<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      구매확정하기<router-link to="" @click.native="closeModal()"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_22">
      <div class="top">
        <ul>
          <li>구매확정 이후에는 교환/반품이 불가능합니다.</li>
          <li>꼭 상품을 받으신 후 구매확정을 진행해주세요.</li>
        </ul>
      </div>
      <OrderItemConfirm
        :orderData="orderData"
        :orderSeq="orderSeqC"
        :itemSeq="itemSeqC"
        :optionSeq="optionSeqC"
        :export_code="export_code"
      />
      <div class="bottom">
        <ul>
          <li>
            구매확정 후 해당 상품 리뷰 평가하기에 참여하면 포인트를 드립니다.
          </li>
          <li>
            각종 평점 매기기는 리뷰의 [구매하기]버튼을 누르고 해당 상품을 구매한
            경우에만 적용됩니다.
          </li>
        </ul>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_btn_2">
      <router-link to="" class="black" @click.native="buyConfirim()"
        >구매확정</router-link
      ><router-link to="" class="gray" @click.native="closeModal()"
        >취소</router-link
      >
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <!-- <div class="rn_my_box_btn yellow">
      <div><router-link to="">구매확정 후 리뷰 평가하러가기</router-link></div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderItemConfirm from "@/components/mypage/order/OrderItemConfirm";

export default {
  data() {
    return {
      dOrderSeq: this.$route.params.order_seq || null,
      dItemSeq: this.$route.params.item_seq || null,
      dOptionSeq: this.$route.params.option_seq || null,
      export_code: this.$route.params.export_code || null,
    };
  },
  props: {
    orderSeq: {
      type: String,
      default: "",
    },
    itemSeq: {
      type: String,
      default: "",
    },
    optionSeq: {
      type: String,
      default: "",
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  // data() {
  //   return {
  //     orderSeq: this.$route.params.order_seq,
  //     itemSeq: this.$route.params.item_seq,
  //     optionSeq: this.$route.params.option_seq,
  //   };
  // },
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getOrderView();
  },
  computed: {
    ...mapState("order", ["orderData", "result", "msg"]),
    ...mapState("mypage", {
      myMsg: "msg",
      myResult: "result",
      buyConfirm: "buyConfirm",
    }),
    orderSeqC() {
      return this.dOrderSeq ? this.dOrderSeq : this.orderSeq;
    },
    itemSeqC() {
      return this.dItemSeq ? this.dItemSeq : this.itemSeq;
    },
    optionSeqC() {
      return this.dOptionSeq ? this.dOptionSeq : this.optionSeq;
    },
  },
  methods: {
    exportCode() {
      let exportCode = "";
      if (!this.export_code) {
        this.orderData.exports.map((ex) => {
          ex.item.map((item) => {
            if (parseInt(item.item_seq) === parseInt(this.itemSeq)) {
              exportCode = item.export_code;
            }
          });
        });
      } else {
        exportCode = this.export_code;
      }
      return exportCode;
    },
    async getOrderView() {
      await this.$store.dispatch("order/getOrderView", {
        order_seq: this.orderSeqC,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
        this.$router.push("/");
        return false;
      }
      if (this.export_code) {
        const index = this.orderData.exports.findIndex(
          (data) => data.export_code === this.export_code
        );
        if (index === -1) {
          this.$toast.default("잘못된 출고번호 입니다.");
          this.$router.push("/");
          return false;
        }
      }
      if (!this.orderData.exports) {
        this.$toast.default("구매확정할 수  있는 상품이 없습니다.");
        this.closeModal();
        return false;
      }
    },
    closeModal(pageInfo = null) {
      if (this.$route.path.includes("/mypage/order_confirm")) {
        this.$router.push("/mypage/payment_list").catch(() => {});
      }
      this.$emit("closeModal", "confirm", pageInfo);
    },
    buyConfirim() {
      this.$confirm(
        "구매 확정하려는 상품을 모두 수령하셨다면 구매 확정을 진행 해주세요"
      )
        .then(async () => {
          await this.$store.dispatch("mypage/buyConfirim", {
            export_code: this.exportCode(),
          });
          // this.$toast.default(this.myMsg);
          if (this.myResult) {
            if (this.buyConfirm) {
              this.$confirm(
                "구매확정 되었습니다. 리뷰 평가를 위해  이동 하시겠습니까?"
              )
                .then(() => {
                  this.$router.push("/mypage/before_evaluation");
                })
                .catch(() => {
                  this.$emit("resetPaymentList");
                  this.closeModal(this.pageInfo);
                });
            } else {
              this.$toast.default(this.myMsg);
              this.$emit("resetPaymentList");
              this.closeModal(this.pageInfo);
            }
          } else {
            this.$toast.default(this.myMsg);
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
  components: {
    OrderItemConfirm,
  },
};
</script>

<style></style>
