<template>
  <div class="section_goods">
    <div class="info">
      <div class="left">{{ orderData.orders.mstep }}</div>
      <div class="right">{{ day(orderData.orders.deposit_date) }} 결제</div>
    </div>
    <div class="num"><b>주문번호</b>&nbsp;&nbsp;{{ orderSeq }}</div>
    <div class="goods_num">
      <b>출고번호</b>&nbsp;&nbsp;{{ itemList.length > 0 && exportCode() }}
    </div>
    <div v-for="(item, index) in itemList" :key="index">
      <div class="goods main" v-if="parseInt(item.rt_ea) > 0">
        <div class="goods_seq" style="padding-top: 15px">
          <label>
            <span>본상품</span>
          </label>
          <p>
            상품번호
            {{ item.goods_seq }}{{ item.item_seq }}{{ item.item_option_seq }}
          </p>
        </div>
        <div class="img">
          <img :src="item.image" @error="replaceImg" :alt="item.goods_name" />
        </div>
        <div class="txt">
          <h3>{{ item.goods_name }}</h3>
          <!-- <h4>
            {{ won(parseInt(item.sale_price) * parseInt(item.ea)) }}원
            <span
              v-if="
                parseInt(item.sale_price) * parseInt(item.ea) <
                parseInt(item.out_price) * parseInt(item.ea)
              "
            >
              {{ won(parseInt(item.out_price) * parseInt(item.ea)) }}원
            </span>
          </h4> -->
          <h4>
            {{ won(parseInt(item.sale_price)) }}원
            <span v-if="parseInt(item.sale_price) < parseInt(item.out_price)">
              {{ won(parseInt(item.out_price)) }}원
            </span>
          </h4>
          <p class="shipping_txt">(배송비 제외)</p>
          <p>수량 : {{ item.rt_ea }}개</p>
          <p v-if="item.title1">{{ item.title1 }} : {{ item.option1 }}</p>
          <p v-if="item.title2">{{ item.title2 }} : {{ item.option2 }}</p>
          <p v-if="item.title3">{{ item.title3 }} : {{ item.option3 }}</p>
          <p v-if="item.title4">{{ item.title4 }} : {{ item.option4 }}</p>
          <p v-if="item.title5">{{ item.title5 }} : {{ item.option5 }}</p>
        </div>
      </div>
      <template v-for="(sub, idx) in item.suboptions">
        <div :key="idx" class="goods sub" v-if="parseInt(sub.ea) > 0">
          <div class="suboptions">
            <label>
              <span>추가 상품</span>
            </label>
            <p>
              {{ sub.title }}({{ sub.suboption }})
              <!-- <span>{{ won(parseInt(sub.price) * parseInt(sub.ea)) }}원</span> -->
              <span>{{ won(parseInt(sub.price)) }}원 </span>
              {{ sub.ea }}개
            </p>
          </div>
        </div>
      </template>
      <template v-if="index + 1 >= itemList.length">
        <p
          v-if="parseInt(providerSeq) === 1 && providerName"
          class="privder_info"
        >
          <span class="left">{{ providerName }}</span>
        </p>
        <p
          v-if="parseInt(providerSeq) !== 1 && providerName"
          class="privder_info"
        >
          <span class="left"
            >{{ providerName }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</span
          >
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import _sortBy from "lodash/sortBy";
export default {
  data() {
    return {
      itemListArray: [],
      orderIndex: 0,
      providerName: "",
      providerSeq: 0,
    };
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    orderSeq: {
      typeo: String,
      default: "",
      required: true,
    },
    itemSeq: {
      typeo: String,
      default: "",
      required: true,
    },
    optionSeq: {
      typeo: String,
      default: "",
      required: true,
    },
    export_code: {
      typeo: String,
      default: "",
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    init() {
      this.itemListArray = [];
    },
    day(str) {
      return this.$moment(str).format("YYYY-MM-DD");
    },
    won(str) {
      return this.$won(str);
    },
    exportCode() {
      let exportCode = "";
      if (!this.export_code) {
        this.orderData.exports.map((ex) => {
          ex.item.map((item) => {
            if (parseInt(item.item_seq) === parseInt(this.itemSeq)) {
              exportCode = item.export_code;
            }
          });
        });
      } else {
        exportCode = this.export_code;
      }
      return exportCode;
    },
  },
  computed: {
    itemList() {
      let data = {
        addPrice: 0,
        suboptions: [],
      };
      if (this.orderData.exports) {
        let exportCode = this.exportCode();
        this.orderData.exports.forEach((ex) => {
          if (ex.item) {
            ex.item.forEach((item) => {
              data = {
                addPrice: 0,
                suboptions: [],
              };
              if (
                item.export_code === exportCode &&
                item.opt_type.toLowerCase() !== "sub"
              ) {
                this.providerName = ex.provider_name;
                this.providerSeq = ex.shipping_provider_seq;
                data.provider_seq = ex.shipping_provider_seq;
                data.provider_name = ex.provider_name;
                data.out_price = item.out_price;
                data.sale_price = item.sale_price;
                data.image = item.image;
                data.goods_name = item.goods_name;
                data.goods_seq = item.goods_seq;
                data.item_option_seq = item.item_option_seq;
                data.item_seq = item.item_seq;
                data.title1 = item.title1;
                data.title2 = item.title2;
                data.title3 = item.title3;
                data.title4 = item.title4;
                data.title5 = item.title5;
                data.option1 = item.option1;
                data.option2 = item.option2;
                data.option3 = item.option3;
                data.option4 = item.option4;
                data.option5 = item.option5;
                data.opt_type = item.opt_type;
                data.ea = item.ea ? item.ea : 0;
                data.rt_ea = item.rt_ea ? item.rt_ea : 0;
                data.individual_return = item.individual_return;
                data.declaration = item.declaration;
              }
              if (
                item.export_code === exportCode &&
                item.opt_type.toLowerCase() === "sub"
              ) {
                let title =
                  item.title1 ||
                  item.title2 ||
                  item.title3 ||
                  item.title4 ||
                  item.title5;
                let suboption =
                  item.option1 ||
                  item.option2 ||
                  item.option3 ||
                  item.option4 ||
                  item.option5;
                let price = parseInt(item.price);
                data.addPrice += parseInt(item.price);
                data.suboptions.push({
                  title,
                  suboption,
                  ea: item.ea ? item.ea : 0,
                  price,
                  opt_type: item.opt_type,
                  item_suboption_seq: item.option_seq,
                  rt_ea: item.rt_ea ? item.rt_ea : 0,
                  individual_return: item.individual_return,
                  declaration: item.declaration,
                });
              }
              // console.log(data);
              data.suboptions = _sortBy(data.suboptions, "item_suboption_seq");
              this.itemListArray.push(data);
            });
          }
        });
      }
      return this.itemListArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.section_goods {
  .goods {
    border-bottom: 1px solid #cccccc;
    &.main {
      padding-bottom: 15px;
    }
    .goods_seq {
      padding-bottom: 10px;
      p {
        margin-top: 10px;
      }
    }
    .suboptions {
      padding: 10px 0px;
      p {
        font-size: 14px;
        color: #545454;
        font-weight: 600;
        padding: 10px 0 0 0;
        span {
          font-size: 16px;
          color: black;
        }
      }
      .ea_txt {
        font-size: 13px;
        line-height: 18px;
        color: #6d6d6d;
        font-weight: 400;
      }
      input:disabled {
        background-color: #dbdbdb;
      }
    }
    .txt {
      padding-top: 0;
      .shipping_txt {
        font-size: 11px;
        margin-bottom: 10px;
        line-height: 11px;
      }
      h4 {
        margin-bottom: 5px;
      }
      p {
        line-height: 18px;
      }
    }
    .ea_box {
      width: 100%;
      overflow: hidden;
      margin-left: 5px;
      input {
        width: 25px;
        height: 25px;
        border: 1px solid #545454;
        background: #fff;
        text-align: center;
        font-size: 14px;
      }
      a {
        display: inline-block;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        background: #d7d7d7;
        color: #545454;
        font-weight: 600;
      }
    }
    .main_ea_box {
      width: 100%;
      position: relative;
      float: left;
      padding: 10px 0 0 0;
    }
  }
  .privder_info {
    padding: 15px 0 0 0;
    .right {
      float: right;
    }
  }
  .goods_num {
    border-bottom: 1px solid #cccccc;
    padding: 0 0 10px 0;
  }
}
</style>
